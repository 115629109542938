import React from "react";

const StoryEditor = (props) => {
 //  console.log(props);
 let selectedStoryData = props.selectedStoryData;

 const setSelectedStoryData = props.setSelectedStoryData;
 const autoSave = props.autoSave;

 if (selectedStoryData === null) return <></>;
 return (
  <>
   <h2>Story Editor:</h2>
   Title: <input placeholder="Title:" value={selectedStoryData.Title} /> <br />
   Voice: <input placeholder="Voice:" value={selectedStoryData.Voice} /> <br />
   <br /> Story:{" "}
   <textarea
    type="textarea"
    rows="20"
    style={{ width: "80%", minHeight: "50px", maxHeight: "500px", height: "auto" }}
    placeholder="Story:"
    value={selectedStoryData.Story}
    onChange={(e) => {
     setSelectedStoryData({ ...selectedStoryData, Story: e.target.value });
     autoSave(selectedStoryData.id, { ...selectedStoryData, Story: e.target.value });
    }}
   />
   <br />
   <br />
   <div style={{ width: "60%", minHeight: "50px" }}>
    Story Images: {} <img style={{ maxHeight: "150px" }} src={selectedStoryData.Images}></img> <button> + </button>
   </div>
   <br />
   <div style={{ width: "60%", minHeight: "50px" }}>
    Story Videos: {} <img style={{ maxHeight: "150px" }} src={selectedStoryData.Images}></img> <button> + </button>
   </div>
   <br />
   <br />
  </>
 );
};

export default StoryEditor;
