import React from "react";
import styled from "styled-components";

const CodeBlock = styled.pre`
 background-color: #272822;
 color: #f8f8f2;
 padding: 1rem;
 margin: 1rem 0;
 font-size: 1rem;
 max-width: 100vw;
 height: 100%;
 overflow-x: auto;
`;

const OutputLog = ({ output }) => {
 return (
  <>
   <h4>Output Log:</h4>

   <CodeBlock>
    {output.map((el) => {
     return <div>{el.data} </div>;
    })}
   </CodeBlock>
  </>
 );
};

export default OutputLog;
