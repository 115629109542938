import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import styled from "styled-components";
import JobForm from "./components/JobForm";
import JobStatus from "./components/JobStatus";
import OutputLog from "./components/OutputLog";
import StoryEditor from "./components/StoryEditor";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const AppContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const App = () => {
  const [vmList, setVmList] = useState([]);
  const [instanceData, setInstanceData] = useState(null);

  const [lastFetched, setLastFetched] = useState(0);
  const [vmName, setVmName] = useState("");
  const [activeVMData, setActiveVMData] = useState({});
  const [jobName, setJobName] = useState("");
  const [showAddStoryForm, setShowAddStoryForm] = useState(false);
  const [newStoryTitle, setNewStoryTitle] = useState("");
  const [outputLogState, setOutputLogState] = useState([]);

  const [user, setUser] = useState(null);
  const [storyData, setStoryData] = useState(null);
  const [selectedStoryData, setSelectedStoryData] = useState(null);

  const [lastSavedAt, setLastSavedAt] = useState(null);

  const autoSave = async (storyId, newData) => {
    const db = firebase.firestore();

    db.collection("StoryData")
      .doc(storyId)
      .update(newData)
      .then((e) => {
        console.log("Document updated successfully!");
        getStoryData();
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };

  const getStoryData = async (userProp) => {
    console.log("getStoryData");
    console.log(user);
    if (user === null && userProp === null) {
      return 1;
    }
    const db = firebase.firestore();

    db.collection("StoryData")
      .get()
      .then((querySnapshot) => {
        let tempJSON = [];
        console.log(querySnapshot);
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
          tempJSON.push({ ...doc.data(), id: doc.id });
        });
        setStoryData(tempJSON);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  const handleAddStory = async () => {
    if (!newStoryTitle) return;
    const db = firebase.firestore();
    await db.collection("StoryData").add({ Title: newStoryTitle });
    setNewStoryTitle("");
    setShowAddStoryForm(false);
    getStoryData();
  };

  const handleSignIn = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      setUser(result.user).then(() => {
        getStoryData();
      });
      localStorage.setItem("firebaseUser", JSON.stringify(result.user));
    } catch (error) {
      console.log(error);
    }
  };

  const handleStart = (e) => {
    e.preventDefault();
    axios
      .post(
        "http://127.0.0.1:3001/start",
        { data: { activeVMData } },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        console.log(res);
        const tempJSON = outputLogState;
        tempJSON.push(res);
        setOutputLogState(tempJSON);
      })
      .catch((err) => console.log(err));
  };

  const handleStop = (e) => {
    e.preventDefault();
    axios
      .post(
        "http://127.0.0.1:3001/stop",
        { data: { activeVMData } },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        console.log(res);
        const tempJSON = outputLogState;
        tempJSON.push(res);
        setOutputLogState(tempJSON);
      })
      .catch((err) => console.log(err));
  };

  function startTimer() {
    const timeInput = document.getElementById("timeInput");
    const countdownTime = parseInt(timeInput.value);
    let timeLeft = countdownTime;

    const countdownInterval = setInterval(() => {
      if (timeLeft <= 0) {
        clearInterval(countdownInterval);
        timeInput.value = "Time's up!";
        console.log("Time's up!");
        handleStop();
      } else {
        timeInput.value = timeLeft;
        console.log(timeLeft);
        timeLeft--;
      }
    }, 1000);
  }

  const handleSetPassword = async (e) => {
    const tempJSON = outputLogState;
    tempJSON.push(JSON.stringify("Setting Password"));
    setOutputLogState(tempJSON);
    axios
      .post(
        "http://127.0.0.1:3001/setvmpasswd",
        { data: { activeVMData } },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(async (res) => {
        console.log(res);
        const tempJSON = outputLogState;
        tempJSON.push(res);
        setOutputLogState(tempJSON);
      })
      .catch((err) => console.log(err));
  };

  function fetchInstanceList() {
    fetch("http://127.0.0.1:3001/instances")
      .then((response) => response.text())
      .then((data) => {
        // console.log(data);

        let parsedJSON = JSON.parse(JSON.parse(data));
        // console.log(parsedJSON);

        let instanceArray = [];
        parsedJSON.forEach((instanceResEls) => {
          const intanceName = instanceResEls.name;
          const intanceZone = instanceResEls.zone;
          const intanceType = instanceResEls.machineType;
          const intanceStatus = instanceResEls.status;
          const intanceProjectId = instanceResEls.zone.split("projects/")[1].split("/")[0];

          let instanceNatIP;

          try {
            instanceNatIP = instanceResEls.networkInterfaces[0].accessConfigs[0].natIP;
          } catch (error) {
            instanceNatIP = -1;
          }

          instanceArray.push({
            name: intanceName,
            zone: intanceZone,
            type: intanceType,
            status: intanceStatus,
            projectId: intanceProjectId,
            instanceNatIP: instanceNatIP,
          });
        });

        console.log(instanceArray);
        let finalHtml = `
    ${instanceArray.map((el) => {
      return <div>{el.name} "X" </div>;
    })}
    ${parsedJSON.length} Instances.`;
        // document.getElementById("InstanceListSpanID").innerHTML = String(finalHtml);
        setInstanceData(instanceArray);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    fetchInstanceList(setInstanceData);
    setLastFetched(Date.now());

    const firebaseConfig = {
      apiKey: "AIzaSyCCI-FTczdqZkzxv6LwMOQEGHxTu-AsUV4",
      authDomain: "genresphere.firebaseapp.com",
      projectId: "genresphere",
      storageBucket: "genresphere.appspot.com",
      messagingSenderId: "250746453602",
      appId: "1:250746453602:web:315c3856c4b0b170b0db3b",
      measurementId: "G-YPDZF1CKJN",
    };

    firebase.initializeApp(firebaseConfig);

    console.log("Use Effect Mounted");
    console.log("Ran getStoryData");

    // const user = JSON.parse(localStorage.getItem("firebaseUser"));
    const userLocSto = JSON.parse(localStorage.getItem("firebaseUser")) || false;
    // If credentials exist, sign in user
    if (userLocSto) {
      console.log("user");
      console.log(userLocSto);
      console.log(userLocSto.displayName);
      setUser(userLocSto);
      try {
        const credential = firebase.auth.GoogleAuthProvider.credential(
          userLocSto.accessToken,
          userLocSto.idToken
        );
        firebase
          .auth()
          .signInWithCredential(credential)
          .then((userCredential) => {
            // User signed in successfully, do something
            console.log("User signed in:", userCredential.user);
          })
          .catch((error) => {
            // Error signing in user, do something else
            console.log("Error signing in user:", error);
          });
      } catch (error) {}
    }

    // Listen for changes in the user's authentication state
    firebase.auth().onAuthStateChanged((userProp) => {
      if (userProp) {
        console.log("User logged in:", userProp);
        // User is logged in, save credentials to local storage
        localStorage.setItem("firebaseUser", JSON.stringify(userProp));
        getStoryData(userProp);
      } else {
        console.log("User logged out");
        // User is logged out, remove credentials from local storage
        localStorage.removeItem("firebaseUser");
        setUser(null);
      }
    });
    console.log("Ran autologin");
    fetchInstanceList();
    console.log("Ran fetchInstanceList");
    const interval = setInterval(() => {
      fetchInstanceList(setInstanceData);
      setLastFetched(Date.now());
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  if (user === null) {
    return (
      <div>
        {user ? (
          <p>Welcome, {user.displayName}!</p>
        ) : (
          <button onClick={handleSignIn}>Sign in with Google</button>
        )}
      </div>
    );
  }

  return (
    <AppContainer>
      <Container>
        <Row style={{ textAlign: "center", position: "relative", top: "-25px", width: "100%" }}>
          <div>
            {user ? (
              <p>Welcome, {user.displayName}!</p>
            ) : (
              <button onClick={handleSignIn}>Sign in with Google</button>
            )}
          </div>
          <Row style={{ display: "flex", width: "100%" }}>
            <h2 style={{ margin: "auto" }}>GenreSphere Story Manager</h2>
            <Button style={{ marginLeft: "auto" }} onClick={() => setShowAddStoryForm(true)}>
              Add Story
            </Button>
          </Row>
          <Row style={{ width: "100vw", margin: "auto", textAlign: "center" }}>
            <b>Status:</b> Ready, <b>Last Fetched:</b> {new Date(lastFetched).toISOString()}
          </Row>
          <br />
          <Row
            style={{
              display: "flex",
              backgroundColor: "#150133",
              paddingBottom: "20px",
              paddingTop: "20px",
              width: "100vw",
            }}
          >
            <Col style={{ margin: "auto" }}>
              Complete
              <br />
              {storyData &&
                storyData.map((v) => {
                  if (v.Status === "Complete") {
                    return (
                      <>
                        <button
                          onClick={() => {
                            // update to active
                            const db = firebase.firestore();
                            const collectionRef = db.collection("StoryData");
                            collectionRef
                              .doc(v.id)
                              .update({
                                Status: "Active",
                              })
                              .then(() => {
                                console.log("Document updated successfully!");
                              })
                              .catch((error) => {
                                console.error("Error updating document: ", error);
                              });
                            getStoryData();

                            console.log(v);
                            setSelectedStoryData(v);
                            console.log(selectedStoryData);
                          }}
                        >
                          {v.Title}
                        </button>{" "}
                      </>
                    );
                  }
                })}
            </Col>
            <Col style={{ margin: "auto" }}>
              Active
              <br />
              {storyData &&
                storyData.map((v) => {
                  if (v.Status === "Active") {
                    return (
                      <>
                        <button
                          onClick={() => {
                            // update to active
                            const db = firebase.firestore();
                            const collectionRef = db.collection("StoryData");
                            collectionRef
                              .doc(v.id)
                              .update({
                                Status: "Active",
                              })
                              .then(() => {
                                console.log("Document updated successfully!");
                              })
                              .catch((error) => {
                                console.error("Error updating document: ", error);
                              });

                            getStoryData();
                            console.log(v);
                            setSelectedStoryData(v);
                            console.log(selectedStoryData);
                          }}
                        >
                          {v.Title}
                        </button>{" "}
                      </>
                    );
                  }
                })}
            </Col>
            <Col style={{ margin: "auto" }}>
              Upcoming
              <br />
              {storyData &&
                storyData.map((v) => {
                  if (v.Status !== "Complete" && v.Status !== "Active") {
                    return (
                      <>
                        <button
                          onClick={() => {
                            // update to active
                            const db = firebase.firestore();
                            const collectionRef = db.collection("StoryData");
                            collectionRef
                              .doc(v.id)
                              .update({
                                Status: "Active",
                              })
                              .then(() => {
                                console.log("Document updated successfully!");
                              })
                              .catch((error) => {
                                console.error("Error updating document: ", error);
                              });
                            getStoryData();
                            console.log(v);
                            setSelectedStoryData(v);
                            console.log(selectedStoryData);
                          }}
                        >
                          {v.Title}
                        </button>{" "}
                      </>
                    );
                  }
                })}
            </Col>
          </Row>
        </Row>
        <Row hidden={user === null} style={{ width: "100vw", textAlign: "center" }}>
          <StoryEditor
            autoSave={autoSave}
            selectedStoryData={selectedStoryData}
            setSelectedStoryData={setSelectedStoryData}
            setStoryData={setStoryData}
          />
        </Row>
        <Row hidden={user === null} style={{ display: "flex", width: "100vw" }}>
          <Col style={{ width: "100vw", textAlign: "center" }}>
            {" "}
            <span id="InstanceListSpanID">
              {" "}
              <div
                style={{
                  maxWidth: "100vw",
                  display: "flex",
                  marginBottom: "10px",
                  backgroundColor: "#121212",
                }}
              >
                <Col style={{ margin: "auto" }}>{"Select"}</Col>
                <Col style={{ margin: "auto" }}>{"Name"}</Col>
                <Col style={{ margin: "auto" }}>{"Status"} </Col>
                <Col style={{ margin: "auto" }}>{"Type"}</Col>
                <Col style={{ margin: "auto" }}>{"Zone"}</Col>
                <Col style={{ margin: "auto" }}>{"Live IP"}</Col>
              </div>
              {instanceData &&
                user != null &&
                instanceData.map((el, index) => {
                  return (
                    <div
                      hidden={user === null}
                      style={{
                        width: "100vw",
                        display: "flex",
                        marginLeft: "0px",
                        marginBottom: "0px",
                        backgroundColor: index % 2 === 1 ? "#0a0a0a" : "#121212",
                      }}
                    >
                      <Col style={{ margin: "auto" }}>
                        <button
                          onClick={(e) => {
                            setActiveVMData({
                              name: el.name,
                              status: el.status,
                              zone: el.zone,
                              type: el.type,
                              projectId: el.projectId,
                            });
                            setVmName(el.name);
                          }}
                        >
                          Select VM
                        </button>
                      </Col>
                      <Col style={{ margin: "auto" }}>{el.name}</Col>
                      <Col style={{ margin: "auto" }}>{el.status} </Col>
                      <Col style={{ margin: "auto" }}>{el.type.split("machineTypes/")[1]}</Col>
                      <Col style={{ margin: "auto" }}>{el.zone.split("/zones/")[1]}</Col>
                      <Col style={{ margin: "auto" }}>{el.instanceNatIP || "Offline"}</Col>
                    </div>
                  );
                })}
            </span>
          </Col>{" "}
        </Row>{" "}
        <br />
        <br />
        <Row style={{ width: "100vw", textAlign: "center" }}>
          <Form.Group controlId="formVmName">
            <Form.Label>Virtual Machine Name </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter VM name"
              value={vmName}
              onChange={(e) => setVmName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formJobName">
            <Form.Label>Job Name </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter job name"
              value={jobName}
              onChange={(e) => setJobName(e.target.value)}
            />
          </Form.Group>
          <br />
          <Button onClick={handleStart} variant="primary" type="submit">
            Start
          </Button>{" "}
          <Button onClick={handleStop} variant="primary">
            Stop
          </Button>{" "}
          <Button
            variant="secondary"
            type="button"
            onClick={() => {
              handleSetPassword();
            }}
          >
            Set Password
          </Button>
          <br />
          <br /> Shutdown Timer:{" "}
          <input type="number" id="timeInput" placeholder="Enter time in seconds" />{" "}
          <Button onClick={() => startTimer()}>Start Shutdown Timer </Button>
        </Row>
        <div
          hidden={!showAddStoryForm}
          style={{
            position: "absolute",
            backgroundColor: "#151226",
            border: "solid 2px",
            borderRadius: "15px",
            top: "50%",
            left: "50%",
            zIndex: 999,
            width: "500px",
            height: "300px",
            transform: `translate(-50%,-50%)`,
            textAlign: "center",
          }}
        >
          {" "}
          <br />
          <br /> Enter Title: <br /> <br />
          <input
            value={newStoryTitle}
            onChange={(e) => {
              setNewStoryTitle(e.target.value);
            }}
          />
          <br /> <br />
          <button
            onClick={() => {
              handleAddStory();
            }}
          >
            Save
          </button>{" "}
          <button
            onClick={() => {
              setShowAddStoryForm(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Container>
      <OutputLog output={outputLogState} />
      {vmList.map((vm) => (
        <div key={vm.name}>
          <h4>{vm.name}</h4>
          <p>{vm.status}</p>
        </div>
      ))}
    </AppContainer>
  );
};

export default App;
